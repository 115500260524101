import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Input, TextArea } from 'semantic-ui-react';
import { v4 as uuid_v4 } from "uuid";
import { DISPLAY_TIME_FORMAT } from '../constants/DISPLAY_TIME_FORMAT';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import {ANNOTATION_TYPE} from "../VideoPlayer";

const AnnotationAddEditForm = ({
  t,
  controlState,
  annotationOptions,
  annotation: passedAnnotation,
  newAnnotationStartTime,
  newAnnotationEndTime,
  onSave
}) => {

  const newAnnotation = {
    id: uuid_v4(),
    timeStart: newAnnotationStartTime,
    timeEnd: newAnnotationEndTime,
    category: { code: '', type: ANNOTATION_TYPE.INSTANT.value },
     level: 1
  };

  const [annotation, setAnnotation] = useState({
    ...newAnnotation,
    ...passedAnnotation
  });

  const start = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeStart);
  const end = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd);

  let timeString = start.toString();
  if (annotation.timeEnd != null && annotation.timeStart !== annotation.timeEnd) {
    timeString += ` - ${end}`;
  }

  const buildCategoryOptions = () => {
    const options = Object.values(annotationOptions?.categories)
      .map(category => {
        return {
          key: category.code,
          text: t(category.titleTranslationKey, category.titleDefault, category.code),
          value: { type: category.type, code: category.code, color: category.color, level: category.level?category.level:1 }
        };
      });
    return options;
  };

  const handleSave = (e) => {
    GeneralHelpers.stopEvent(e);
    if (onSave) {
      onSave(annotation);
    }
  }

  const categoryOptions = useMemo(buildCategoryOptions, [annotationOptions?.categories]);

  return (
    <Form>
      <Form.Field>
        <label>{annotation.category.type === ANNOTATION_TYPE.INSTANT.value ?(t("ANNOTATIONS_TIME", "Time")):(t("ANNOTATIONS_DURATION","Duration"))}</label>
        <Input
          value={timeString || ''}
          name={"time"}
          fluid
          required
          disabled
          transparent
        />
      </Form.Field>
      <Dropdown
        fluid
        selection
        options={categoryOptions}
        onChange={(_e, data) => {
          let changedAnnotation = { ...annotation, category: data.value }
          if(data.value.type === ANNOTATION_TYPE.INTERVAL.value) {
            changedAnnotation.timeEnd = changedAnnotation.timeStart + Math.floor(controlState.durationMilliseconds * 0.05);
          }else {
            changedAnnotation.timeEnd = null;
          }
          changedAnnotation.level = data.value.level?data.value.level:1
          setAnnotation(changedAnnotation);
        }
        }
        value={annotation.category}
      />
      <Form.Field>
        <label>{t('ANNOTATION_EDIT_NAME', "Name")}</label>
        <Input
          value={annotation.name || ''}
          name={"name"}
          fluid
          required
          onChange={(_e, data) => setAnnotation({ ...annotation, name: data.value })}
        />
      </Form.Field>
      <Form.Field>
        <label>{t('ANNOTATION_EDIT_NOTES', "Notes")}</label>
        <TextArea
          name={"notes"}
          style={{ minHeight: 200 }}
          value={annotation.notes || ''}
          onChange={(_e, data) => setAnnotation({ ...annotation, notes: data.value })}
        />
      </Form.Field>

      <Button onClick={handleSave} primary>
        {t(["TODO", "GLOBAL_BUTTON_SUBMIT"], "Submit")}
      </Button>

    </Form>
  );
};

export default memo(withTranslation()(AnnotationAddEditForm));
