import React, {memo, useCallback, useMemo, useState} from "react";
import {Card, Icon, Label, Message} from "semantic-ui-react";
import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import AccordianReveal from "../../generic/AccordianReveal";
import QuestionEditorRevealedCardComponent from "./QuestionEditorRevealedCardComponent";
import SecondaryButton from "../../generic/SecondaryButton";
import AceEditor from "react-ace";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import {useTranslation} from "react-i18next";


// If this component isnt memoised all question card components will rerender
// when a value is changed in any question.
const QuestionEditorCardComponent = memo(({
  questionDefinition,
  index,
  isReadOnly = false,
  dispatch,
  questionnaireDefinitionCode,
  workflowTasks,
  workflowBehaviour,
  translations,
  changedTranslations,
  isFocused
}) => {
  const {t} = useTranslation();

  const shouldShowVideoUrl = questionDefinition.type === QUESTION_TYPES.MONITORED_VIDEO || questionDefinition.type === QUESTION_TYPES.RENDER_VIDEO;
  const shouldShowUrl = questionDefinition.type === QUESTION_TYPES.RENDER_IMAGE;
  const shouldShowValue = questionDefinition.calculated;

  const label = useMemo(()=>{
    return [...translations, ...changedTranslations]?.find(t=>t.code === `questionnaire_${questionnaireDefinitionCode}_questions_${questionDefinition.code}_label`)
  }, [changedTranslations, questionDefinition.code, questionnaireDefinitionCode, translations])
  const url = useMemo(()=>{
    if(!shouldShowUrl) return null
    return [...translations, ...changedTranslations]?.find(t=>t.code === `questionnaire_${questionnaireDefinitionCode}_questions_${questionDefinition.code}_url`)
  }, [changedTranslations, questionDefinition.code, questionnaireDefinitionCode, shouldShowUrl, translations])
  const videoUrl = useMemo(()=>{
    if(!shouldShowVideoUrl) return null
    return [...translations, ...changedTranslations]?.find(t=>t.code === `questionnaire_${questionnaireDefinitionCode}_questions_${questionDefinition.code}_videoUrl`)
  }, [changedTranslations, questionDefinition.code, questionnaireDefinitionCode, shouldShowVideoUrl, translations])

  const shouldShowLabel = label && !questionDefinition.hidden;

  const [shouldShowJson, setShouldShowJson] = useState(false);
  const toggleShouldShowJson = () => setShouldShowJson(!shouldShowJson)


  const move = (direction) => {
    dispatch({
      type: ACTION_TYPES.MOVE_QUESTION,
      payload: {
        index, direction
      }
    })
  }

  const focus = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.TOGGLE_FOCUS,
      payload: questionDefinition.code
    })
  }, [dispatch, questionDefinition.code])

  const [jsonParseError, setJsonParseError] = useState(false)
  const [jsonCodeError, setJsonCodeError] = useState(false)
  const [tempValue, setTempValue] = useState("")
  const onChange = async (v) => {
    try {
      const value = JSON.parse(v);
      setJsonParseError(false)
      if(value.code !== questionDefinition.code) {
        setJsonCodeError(true)
      } else {
        setJsonCodeError(false)
      }
      dispatch({
        type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
        payload: value,
      });
    } catch (e) {
      await setTempValue(v)
      setJsonParseError(true)
    }

  };

  return <div style={{width: "100%", padding: "1rem 0"}}>
    <Card fluid  style={{padding: "1rem"}}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <h4 style={{margin: 0}}>{`${questionDefinition.type} - ${questionDefinition.code}`}</h4>
        <div style={{display: "flex"}}>
          <SecondaryButton noPadding rounded height={"30px"} width={"30px"} onClick={()=>move(1)}><Icon style={{margin: 0}} name={"angle down"} /></SecondaryButton>
          <label style={{lineHeight: "250%", color: "#9D9FA2"}}>{questionDefinition.sequence}</label>
          <SecondaryButton noPadding rounded height={"30px"} width={"30px"} onClick={()=>move(-1)}><Icon style={{margin: 0}} name={"angle up"} /></SecondaryButton>
          <SecondaryButton primary={shouldShowJson} noPadding rounded height={"30px"} width={"30px"} onClick={toggleShouldShowJson}><span>{"{}"}</span></SecondaryButton>
          <SecondaryButton primary={isFocused} noPadding rounded height={"30px"} width={"30px"} onClick={focus}><Icon style={{margin: 0}} name={"search"} /></SecondaryButton>
        </div>
      </div>
      {shouldShowValue && <p style={{
        color: "#9D9FA2", overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{questionDefinition.value}</p>}
      {shouldShowUrl && <p style={{
        color: "#9D9FA2", overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{url.translation}</p>}
      {shouldShowVideoUrl && <p style={{
        color: "#9D9FA2", overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{videoUrl.translation}</p>}
      {shouldShowLabel && <p style={{
        color: "#9D9FA2", overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{label.translation}</p>}
      {!shouldShowJson && <AccordianReveal displayText={"More"} noPadding>
        <QuestionEditorRevealedCardComponent
            dispatch={dispatch}
            questionDefinition={questionDefinition}
            translations={translations}
            workflowTasks={workflowTasks}
            workflowBehaviour={workflowBehaviour}
        />
      </AccordianReveal>}
      {shouldShowJson && <AceEditor
          readOnly={isReadOnly}
          mode="json"
          height="100%"
          theme="github"
          onChange={onChange}
          name="questionEditor"
          editorProps={{ $blockScrolling: true }}
          value={jsonParseError ? tempValue : JSON.stringify(questionDefinition, null, 2)}
          style={{ minHeight: "200px", width: '100%' }}
      />}
      {shouldShowJson && jsonParseError && <Message error>
        <Message.Content >{t("QUESTION_DEFINITION_JSON_ERROR", "There is an issue with the JSON, value will not save")}</Message.Content>
      </Message>}
      {shouldShowJson && jsonCodeError && <Message warning>
        <Message.Content >{t("QUESTION_DEFINITION_CODE_ERROR", "The question code has been changed, value will not save")}</Message.Content>
      </Message>}
      <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
        {questionDefinition.calculated && <Label>{t("QUESTION_DEFINITION_CALCULATED", "Calculated")}</Label>}
        {questionDefinition.hidden && <Label>{t("QUESTION_DEFINITION_HIDDEN", "Hidden")}</Label>}
        {questionDefinition.required && <Label>{t("QUESTION_DEFINITION_REQUIRED", "Required")}</Label>}
        {questionDefinition.conditions && questionDefinition.conditions.length > 0 && <Label>{t("QUESTION_DEFINITION_CONDITIONS", "Conditions")}</Label>}
      </div>
    </Card>
  </div>
}, (prevProps,nextProps)=>{
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});

export default QuestionEditorCardComponent;
