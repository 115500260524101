import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import Page from "../../components/page/Page";
import CacheService from "../../services/admin/CacheService";

const AdminCacheManagementPage = (props) => {
  const { t } = props;

  const [isRunning, setIsRunning] = useState(false);

  const [infoPopupState, setInfoPopupState] = useState();

  const cacheList = [
    {
      key: "translations",
      text: t("ADMIN_CACHE_MANAGEMENT_TRANSLATIONS", "Translations"),
      link: {
        url: "/app/utils/translations",
        text: "Go to Translation Management > click Clear Caches",
      },
    },
    {
      key: "questionnaireDefinitions",
      text: t(
        "ADMIN_CACHE_MANAGEMENT_QUESTIONNAIRE_DEFINITIONS",
        "Questionnaire definitions"
      ),
      method: {
        delegate: CacheService.refreshQuestionnaireDefinitions,
      },
    },
    {
      key: "expressionContextAnswers",
      text: t(
        "ADMIN_CACHE_MANAGEMENT_EXPRESSION_CONTEXT_ANSWERS",
        "Expression context answers"
      ),
      method: {
        delegate: CacheService.refreshExpressionContextAnswers,
      },
    },
    {
      key: "dynamicSubjectDataExpressionContext",
      text: t(
        "ADMIN_CACHE_MANAGEMENT_DYNAMIC_SUBJECT_DATA_EXPRESSION_CONTEXT",
        "Dynamic Subject Data Expression Context"
      ),
      method: {
        delegate: CacheService.refreshDynamicSubjectDataExpressionContext,
      },
    }
  ];

  const onRefreshClick = async (key) => {
    const cacheListItem = cacheList.find((cli) => cli.key === key);
    if (cacheListItem) {
      await refreshSingleItem(cacheListItem);
    }
  };

  const refreshSingleItem = async (cacheListItem) => {
    setIsRunning(true);
    try {
      if (cacheListItem.method?.delegate) {
        await cacheListItem.method.delegate();
      }
      openInfoPopup(
        t("ADMIN_CACHE_MANAGEMENT_COMPLETE", "Complete"),
        `Refresh of ${cacheListItem.text} is complete`
      );
    } catch (error) {
      openInfoPopup(
        t("GLOBAL_ERROR_TTILE", "Error"),
        `Error: Unable to complete refresh of ${cacheListItem.text}`
      );
      console.error(
        "[AdminCacheManagementPage][refreshSingleItem]",
        cacheListItem,
        error
      );
    } finally {
      setIsRunning(false);
    }
  };

  const openInfoPopup = (headerText, contentText) => {
    setInfoPopupState({
      isOpen: true,
      headerText,
      contentText,
    });
  };

  const closeInfoPopup = () => {
    setInfoPopupState({
      isOpen: false,
    });
  };

  const rows = cacheList.map((cli) => {
    return (
      <Table.Row key={cli.key}>
        <Table.Cell key={cli.key + "_item"}>{cli.text}</Table.Cell>
        <Table.Cell key={cli.key + "_action"}>
          {cli.method && (
            <Button
              primary
              disabled={isRunning}
              onClick={() => onRefreshClick(cli.key)}
            >
              Refresh
            </Button>
          )}
          {cli.link && <Link to={cli.link?.url}>{cli.link?.text}</Link>}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Page
      name="Cache Management"
      header={t("ADMIN_CACHE_MANAGEMENT", "Cache Management")}
    >
      <Modal
        size={"small"}
        open={infoPopupState?.isOpen}
        onClose={closeInfoPopup}
        closeIcon={true}
        closeOnDimmerClick={true}
        closeOnDocumentClick={true}
      >
        <Modal.Header>{infoPopupState?.headerText}</Modal.Header>
        <Modal.Content>
          {infoPopupState?.contentText}

          <div style={styles.closeButton}>
            <Button onClick={closeInfoPopup}>
              {t("GLOBAL_BUTTON_CLOSE", "Close")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <Table selectable>
        <Table.Header>
          <Table.Row key={"header"}>
            <Table.HeaderCell key={"item"}>
              {t("ADMIN_CACHE_MANAGEMENT_ITEM", "Item")}
            </Table.HeaderCell>
            <Table.HeaderCell key={"action"}>
              {t("ADMIN_CACHE_MANAGEMENT_ACTION", "Action")}
            </Table.HeaderCell>
          </Table.Row>
          {rows}
        </Table.Header>
      </Table>
    </Page>
  );
};

const styles = {
  closeButton: {
    marginTop: 20,
    marginBottom: 10,
  },
};

export default withTranslation()(AdminCacheManagementPage);
