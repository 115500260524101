import fetchProgress from "fetch-progress";
import { useEffect, useState} from "react";
import AuthService from "../services/AuthService";
import SubjectService from "../SubjectService";
import GroupPermission from "../GroupPermission";
import AttachmentService from "../services/AttachmentService";

const useUserMedia = (mediaReference, subjectId) => {
  const [isLoading, setIsLoading] = useState(mediaReference ? true : false);
  const [isError, setIsError] = useState(
    mediaReference === undefined ? true : false
  );
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [userMediaUri, setUserMediaUri] = useState(null);
  const [variantReference, setVariantReference] = useState(null);
  const [attachmentDetails, setAttachmentDetails] = useState(null);


  const getAttachmentUriFromReference = async (blurredOnly) => {
    let url;
    if (subjectId) {
      url =
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
          (blurredOnly ? "/attachments/blurred/" : "/attachments/") +
        mediaReference +
        "/inline";
    } else {
      url =
        process.env.REACT_APP_SERVER_ADDRESS +
        "/attachments/" +
        mediaReference +
        "/inline";
    }
    try {
      fetch(url, {
        headers: {
          Authorization: "Bearer " + AuthService.getAuthToken(),
        },
      })
        .then(
          fetchProgress({
            onProgress(progress) {
              setDownloadProgress(progress.percentage);
            },
            onError(err) {
              setIsError(true);
            },
          })
        )
        .then((response) => {
            AttachmentService.getAttachment(subjectId, mediaReference, blurredOnly)
                .then((attachmentDetails) => {setAttachmentDetails(attachmentDetails)})
          if (response.status >= 400){
            setErrorCode(response.status)
            throw new Error();
          }
          setVariantReference(AttachmentService.getAttachmentVariantReferenceFromHeader(response));
          return response.blob();
        })
        .then((imageBlob) => {
          setIsLoading(false);
          setUserMediaUri(URL.createObjectURL(imageBlob));
        })
        .catch(() => {
          setIsError(true);
          setErrorMsg("HTTP_ERROR_LOADING_VIDEO");
          setIsLoading(false);
        });
    } catch (error) {}
  };

  useEffect( () => {
    if (mediaReference) {
        async function initPermissions () {
            const permissions = await SubjectService.getSubjectPermission(subjectId);
            const fullAccessToSubjectMedia = permissions.includes(GroupPermission.VIEW_SUBJECT_MEDIA);
            const accessToBlurredSubjectMedia = permissions.includes(GroupPermission.VIEW_BLURRED_MEDIA);
            return !fullAccessToSubjectMedia && accessToBlurredSubjectMedia;
        }
        initPermissions().then(blurred => {
          getAttachmentUriFromReference(blurred);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, isError,errorCode,errorMsg, downloadProgress, userMediaUri, variantReference ,attachmentDetails};
};

export default useUserMedia;
